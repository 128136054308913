import React from "react";
import cssStyles from "./style.module.css";

const Protect = () => {
  return (
    <div>
      <div className={`${cssStyles.box} rounded-lg`}>
        <img
          src={"/assets/images/logo_transparent.png"}
          alt="logo"
          className={cssStyles.logo}
        />
        <p className={cssStyles.textLead}>
          Zayn Private Beta v2 is only open for the invited users. <br />
          Register today to receive an invitation link
        </p>
        <a
          className={cssStyles.btnSecondary}
          href="https://l53towayerz.typeform.com/zaynfi-beta-v2"
        >
          Register For Our Private Beta
        </a>
      </div>
    </div>
  );
};

export default Protect;
